.c-breadcrumb {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(10);

  ul {
    margin-bottom: 0;
  }
}

.c-breadcrumb__link,
.c-breadcrumb__item a {
  color: $color-grey-dark;
  font-size: rem_calc(16);

  &:hover {
    color: $color-grey-dark;
  }
}
