.c-social-media {
  text-align: right;

  .c-footer__colums-social-media & {
    text-align: right;

    @include bp-large('max') {
      text-align: center;
    }
  }

  @include bp-large('max') {
    text-align: center;
  }
}

.c-social-media__item {
  margin: 0 rem-calc(5) 0 0;

  &:last-child {
    margin-right: 0;
  }
}

.c-social-media__link {
  border: none;

  &:hover {
    .c-social-media__icon {
      fill: $color-theme-alt;
    }
  }
}

.c-social-media__icon {
  height: rem-calc(19);
  width: rem-calc(19);
  transition: $global-transition;
  fill: $color-white;
}
