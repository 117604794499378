.body {
  &__fixed-header {
    padding-top: rem_calc(120);

    @include bp-large('max') {
      padding-top: rem_calc(100);
    }
  }

  &__fixed-header#{&}__floating-header {
    padding-top: 0;
  }
}

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: rem-calc(10);
}
