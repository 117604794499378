.c-galleries {
  &--list {
    padding-top: rem_calc(10);

    @include bp-large('max') {
      padding-top: 0;
    }

    li {
      border-bottom: 1px solid $color-grey-lighter;
    }
  }
}

.c-gallery {
  &__title {
    font-size: rem_calc(16);
    font-weight: bold;

    @include bp-large('max') {
      font-size: rem_calc(16);
    }

    @include bp-medium('max') {
      font-size: rem_calc(14);
    }

    a {
      display: inline-block;

      &:hover {
        color: $color-link-alt;
      }
    }

    .c-galleries--list & {
      font-size: rem_calc(16);
    }
  }
}

.c-gallery__single {
  &-item {
    &-overlay {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .icon {
      width: rem_calc(30);
      height: rem_calc(30);
      fill: $color-white;
    }
  }

  @for $i from 1 through 6 {
    &--cols-#{$i} &-item {
      @if $i < 4 {
        .icon {
          width: rem_calc(40);
          height: rem_calc(40);
        }
      }
    }
  }
}

.c-gallery__entry {
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(10);
}

.c-gallery__inner {
  .c-galleries--thumbs & {
    max-width: rem_calc(400);
    margin-bottom: rem-calc(20);
    border: solid 1px $color-grey-lighter;
  }
}
