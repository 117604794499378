.c-project {
  border-bottom: rem_calc(2) solid #ddd;

  @include bp-medium('max') {
    max-width: rem_calc(250) !important;
  }
}

.c-project__title {
  margin-bottom: rem_calc(15);

  a {
    &:hover {
      color: $color-link;
    }
  }
}

.c-project__category {
  color: $color-grey-medium;
  margin-bottom: rem_calc(30);
  font-weight: 700;
}

.c-project__lead {
  a {
    color: $color-link;
  }
}

.c-project__gallery-image {
  p {
    border: 1px solid $color-grey-light-medium;
  }
}
