$columns: 12;
$page-blocks-width: $global-max-width + rem_calc(50);

.c-blocks {
  &__wrapper {
    &--container {
      max-width: $global-max-width;
    }
  }

  &--container {
    max-width: $global-max-width;
  }

  &-main,
  &-sidebar {
    min-height: 1px;
  }

  &__main {
    width: 100%;

    .c-blocks--has-sidebar & {
      @include bp-large {
        width: 75%;
      }
    }

    .c-blocks--has-sidebar &--8 {
      @include bp-large {
        width: 66.66667%;
      }
    }
  }

  &__sidebar {
    width: 100%;
    max-width: rem_calc($sidebar_max_width);
    opacity: 0;

    &--4 {
      max-width: rem_calc($sidebar_max_width_lg);
    }

    @include bp-large {
      width: 25%;

      &--4 {
        width: 33.33333%;
      }
    }

    @include bp-large('max') {
      order: -1;
    }
  }
}

.c-block {
  width: 100%;
  padding: rem_calc(10 0);

  @include bp-large('max') {
    padding: rem_calc(0);
  }

  &__header {
    width: 100%;
    margin-bottom: 0;
  }

  &--clear {
    width: 100%;
  }

  &--container {
    max-width: $global-max-width;

    @include bp-xxlarge {
      max-width: $page-blocks-width;
    }
  }

  &__bg {
    height: 100%;
    width: 100%;

    .c-block--container.c-block--no-sidebar & {
      width: calc(100% - 72px);
    }

    .c-blocks--has-sidebar & {
      width: calc(100% - 26px);
    }

    .c-block--container.c-block--no-sidebar.c-block--side-left & {
      width: calc(200% - 54px);
      left: rem_calc(36);
    }

    .c-block--side-right.c-block--no-sidebar & {
      width: 150%;
      display: none;
    }

    @include bp-xlarge('max') {
      &--mobile {
        width: 100%;
        min-height: rem_calc(450);
        height: auto;

        &-above {
          margin: rem_calc(-20 0 -10);

          .c-block--floating-header & {
            @include bp-medium {
              margin-top: rem_calc(-70);
            }
          }
        }

        &-below {
          order: 9;
          margin: rem_calc(0 0 -20);

          .c-block--floating-header & {
            margin-top: rem_calc(-40);

            @include bp-medium {
              margin-bottom: rem_calc(-70);
            }
          }
        }
      }
    }

    @include bp-medium('max') {
      &--mobile {
        min-height: rem_calc(300);
      }
    }

    @include bp-small('max') {
      &--mobile {
        min-height: rem_calc(250);
      }
    }
  }

  &__side-bg {
    &-image {
      max-height: 100%;

      @include bp-xlarge('max') {
        max-height: rem_calc(500);
      }

      @include bp-medium('max') {
        max-height: rem_calc(400);
      }
    }
  }

  &__inner {
    max-width: $global-max-width;
    min-height: 100px;
    padding: rem_calc(5 0);

    @include bp-custom($global-max-width) {
      @if($global-max-width > ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
        padding: rem_calc(5 23);
      }
    }

    @include bp-xxlarge {
      @if($global-max-width <= ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
        padding: rem_calc(5 23);
      }
    }

    &--full {
      padding: rem_calc(0);
    }
  }

  &__content {
    padding: rem_calc(13);

    @include bp-large('max') {
      padding: rem_calc(0 13);
    }

    &-inner {
      padding-bottom: rem_calc(10);

      &--padding {
        padding: rem_calc(20 30);

        @include bp-xlarge('max') {
          padding: rem_calc(15 25);
        }

        @include bp-large('max') {
          padding: rem_calc(10 20);
        }

        @include bp-medium('max') {
          padding: rem_calc(13);
        }
      }
    }

    .c-block__inner--full & {
      padding: rem_calc(13 0);
    }

    .c-block--type-slider & {
      padding: 0;

      .c-block__content-inner {
        padding-bottom: 0;
      }
    }

    @include bp-xlarge('max') {
      .c-block--bg-mobile & {
        padding-top: 0;
      }
    }
  }

  &--w-75 .c-block__inner,
  &__content--w-75 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.75;
    }
  }

  &--w-66 .c-block__inner,
  &__content--w-66 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.66;
      width: 66%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.66;
    }
  }

  &--w-50 {
    width: 50%;
    max-width: 50%;

    @include bp-large('max') {
      width: 100%;
      max-width: 100%;
    }
  }

  &__content--w-50 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xlarge-medium {
      max-width: $global-max-width * 0.5;
      width: 50%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.5;
    }
  }
}

@for $i from 5 through 8 {
  div[class*='c-block--h-fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
    @include bp-xxlarge('max') {
      @if( $i >= 7) {
        min-height: rem_calc(600) !important;
      }
    }

    @include bp-large('max') {
      min-height: rem_calc(500) !important;
    }

    @include bp-medium('max') {
      min-height: rem_calc(400) !important;
    }
  }
}

.owl-nav {
  .icon {
    width: 1.5rem;
    height: 3rem;

    @include bp-medium {
      width: 2rem;
      height: 3.5rem;
    }

    .c-block__columns-arrows--dark &,
    .c-block__slider-arrows--dark & {
      fill: $color-theme;
    }
  }
}

.owl-dots {
  .owl-dot {
    width: rem_calc(18);
    height: rem_calc(18);
    margin: rem-calc(0 10);
    background-color: transparent;
    border: rem_calc(3) solid $color-theme-alt;
    vertical-align: middle;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-theme;
      border-color: $color-theme-dark;
    }

    @include bp-xlarge('max') {
      width: rem_calc(16);
      height: rem_calc(16);
    }
  }

  .owl-dot.active {
    width: rem_calc(9);
    height: rem_calc(9);
    background-color: $color-theme-alt;
    border-color: $color-theme-alt;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-white;
      border: rem_calc(1) solid $color-theme-dark;
    }

    @include bp-xlarge('max') {
      width: rem_calc(8);
      height: rem_calc(8);
    }
  }
}

.c-block__slider {
  margin-bottom: rem_calc(0);

  &--full,
  &--full &-slide {
    height: 100vh;
    min-height: rem_calc(350);

    @include bp-medium {
      min-height: rem_calc(350);
    }

    @include bp-large {
      min-height: rem_calc(500);
    }

    @include bp-xxlarge {
      min-height: rem_calc(600);
    }
  }

  @for $i from 5 through 10 {
    div[class*='c-block__slider-slide--fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
      @media (max-width: $bp-large) {
        min-height: rem_calc(500) !important;
      }

      @media (max-width: $bp-medium) {
        min-height: rem_calc(400) !important;
      }
    }

    .item-video[class*='c-block__slider-slide--fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
      @media (max-width: $bp-large) {
        height: rem_calc(500) !important;
      }

      @media (max-width: $bp-medium) {
        height: rem_calc(400) !important;
      }
    }
  }

  &-slide {
    .c-block__slider--auto:not(.c-block__slider--carousel) & {
      min-height: rem_calc(400);

      @include bp-medium {
        min-height: rem_calc(500);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(450);
        }
      }

      @include bp-large {
        min-height: rem_calc(600);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(480);
        }
      }

      @include bp-xxlarge {
        min-height: rem_calc(700);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(500);
        }
      }
    }

    &-caption {
      font-size: rem_calc(20);
      line-height: rem_calc(30);
      letter-spacing: 1.5px;

      @include bp-xlarge('max') {
        font-size: rem_calc(18);
        line-height: rem_calc(28);
      }

      .c-block__slider--no-arrows & {
        padding: 0;
      }

      &-container {
        max-width: $global-max-width;
      }

      &-inner {
        .c-block__slider--full & {
          max-width: $global-max-width - rem_calc(26);
        }
      }

      &--50 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }

        @include bp-large {
          width: 50%;

          .c-blocks--has-sidebar & {
            width: 75%;
          }
        }
      }

      &--66 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }

        @include bp-large {
          width: 67.8%;

          .c-blocks--has-sidebar & {
            width: 75%;
          }
        }
      }

      &--75 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }
      }
    }

    &--full-height-caption {
      padding: 0;

      .c-block__slider-slide-caption-inner {
        padding: rem_calc(40 80);
      }
    }
  }

  &--no-dots {
    padding-bottom: 0;
  }

  .owl-dots {
    bottom: rem_calc(80);

    @include bp-xlarge('max') {
      bottom: rem_calc(40);
    }

    @include bp-medium('max') {
      bottom: rem_calc(20);
    }
  }

  &--carousel {
    margin-bottom: rem_calc(20);

    .c-block--full & {
      margin-bottom: rem_calc(40);
    }

    .owl-dots {
      bottom: rem_calc(-15);

      .c-block--full & {
        bottom: rem_calc(-40);
      }
    }
  }
}

.c-block__columns {
  &-column {
    .c-book__cover {
      width: 100%;
      max-width: 100%;
      float: none;
    }

    &-inner {
      @include bp-medium('max') {
        max-width: rem_calc(400);
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-image {
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-title {
      color: $color-black;
      padding-top: rem_calc(10);
      padding-bottom: rem_calc(5);
      margin-top: rem_calc(10);
      margin-bottom: rem_calc(10);

      a {
        transition: $global-transition;
      }
    }

    &-meta {
      padding-top: rem_calc(5);
      padding-bottom: rem_calc(5);
      font-size: rem_calc(15);
    }

    &-header,
    &-title,
    &-btn,
    &-text,
    &-meta {
      padding-left: rem_calc(10);
      padding-right: rem_calc(10);
    }
  }

  &-btn {
    width: 100%;
    padding-left: rem_calc(10) !important;
    padding-right: rem_calc(10) !important;

    a {
      margin: 0 rem_calc(5);
    }
  }
}

.c-block__tabs {
  &-nav {
    li {
      border: rem_calc(1) solid $color-grey-lighter-medium;
      transition: $global-transition;

      &:hover {
        border-color: $color-grey-lighter;
      }

      .c-block__tabs--dark & {
        border-color: $color-grey-medium-light;

        &:hover {
          border-color: $color-grey-dark;
        }
      }
    }

    .active {
      border-color: $color-grey-lighter;

      .c-block__tabs--dark & {
        border-color: $color-grey-dark;
      }

      a {
        background-color: $color-grey-lighter;
        //color: $color-white;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }

    a {
      background-color: $color-grey-lighter-medium;

      .c-block__tabs--dark & {
        background-color: $color-grey-medium-light;
        color: $color-white;
      }

      &:hover {
        background-color: $color-grey-lighter;
        color: $color-black;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }
  }

  &-content {
    background-color: $color-grey-lighter;
    font-size: rem_calc(16);

    .c-block__tabs--dark & {
      background-color: $color-grey-dark;
      color: $color-white;
    }
  }

  &-accordion {
    &-header {
      font-size: rem_calc(16);

      a {
        border-bottom: rem_calc(1) solid $color-grey-lighter-medium;
        background-color: $color-grey-lighter;
        color: $color-black;
        transition: $global-transition;

        .c-block__tabs--dark & {
          border-color: $color-grey-dark;
          background-color: $color-grey-medium-light;
          color: $color-white;
        }
      }

      .c-arrow-icon {
        margin-top: rem_calc(2);
        fill: $color-black;

        .c-block__tabs--dark & {
          fill: $color-white;
        }
      }

      &.active,
      &:hover {
        a {
          border-bottom: 1px solid $color-grey-lighter;
          background-color: $color-grey-lighter-medium;

          .c-block__tabs--dark & {
            border-color: $color-grey;
            background-color: $color-grey;
            color: $color-white;
          }
        }
      }
    }

    &-content {
      background-color: $color-grey-super-light;
      font-size: rem_calc(16);

      .c-block__tabs--dark & {
        background-color: $color-grey-dark;
        color: $color-white;
      }
    }
  }
}

.c-block__testimonials {
  max-width: calc(100% - 70px);


  .owl-nav {
    .owl-prev,
    .owl-next {
      background-color: $color-grey-lighter;

      &:hover {
        background-color: $color-grey-light;
      }

      &.invisible,
      &.invisible:hover {
        background-color: $color-grey-lighter;
      }
    }
  }

  &-item {
    background-color: $color-grey-lighter;

    .icon-quote {
      fill: #d6d6d6;
    }
  }

  &-meta {
    img {
      width: rem_calc(60) !important; // to override default owl carousel 100% width
    }
  }

  &-info {
    p {
      font-size: rem_calc(14);
    }
  }
}


.c-block__social-media {
  &-widget {
    &-instafeed {
      a {
        background-color: $color-grey-lighter;
      }

      span {
        color: $color-grey;
        font-size: rem_calc(14);
      }
    }
  }
}

.c-block__newsletters-list {
  &-item-inner {
    max-width: rem_calc(410);
    border: 1px solid $color-grey-lighter;
  }
}

.c-instagram__data {
  background-color: rgba(0, 0, 0, 0.8);

  &-inner {
    max-width: rem_calc(500);
    background-color: $color-white;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  }
}

.c-block__contact-form {
  .cform-container {
    left: - rem_calc(13);
    width: calc(100% + 26px);
  }
}
