.o-wrapper {
  max-width: $global-max-width;
}

main.c-wrapper { /* stylelint-disable-line selector-no-qualifying-type */
  min-height: rem-calc(300);

  @include bp-medium() {
    min-height: rem-calc(400);
  }

  @include bp-large() {
    min-height: rem-calc(500);
  }

  @include bp-xxlarge() {
    min-height: rem-calc(650);
  }
}
