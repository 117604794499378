.c-courses__header {
  font-size: rem-calc(20);
}

.c-courses__meta {
  border-bottom: rem-calc(1) solid $color-grey-medium-light;
  color: $color-grey-medium-light;
}

.c-courses__teaser {
  color: $color-grey-medium;
}

.c-courses__filter {
  border: rem-calc(2) solid $color-grey-light-medium;
  border-left-width: 0;
  border-right-width: 0;
}

.c-courses__filter-reset {
  color: $color-grey-dark;

  &:hover {
    color: $color-grey-dark;
  }
}

.c-courses__title {
  padding-left: rem_calc(3);
  padding-right: rem_calc(3);
}

.c-courses__item {
  border-bottom: rem-calc(2) solid $color-grey-light-medium;
}
