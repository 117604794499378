/* ==========================================================================
   #LAYOUT
   ========================================================================== */

// Settings
$global-width: $global-max-width;
$breakpoints: (
  small: 0,
  medium-sm: $bp-medium,
  medium: $bp-large,
  large: $bp-xlarge
);
$breakpoint-classes: (small medium-sm medium large);
$grid-column-gutter: $global-spacing;

// Import Foundation Site grid with dependencies
@import 'foundation-sites/scss/util/util';
@import 'foundation-sites/scss/global';
@import 'foundation-sites/scss/grid/grid';

// Generate custom grid using Foundation Sites Mixins & Functions

.o-layout {
  @include flex-grid-row;

  & .o-layout {
    @include flex-grid-row(nest, $base: false);
  }

  &:not(.is-expanded) .o-layout {
    @include grid-row-size(expand);
  }

  .c-wrapper > & {
    padding-top: rem_calc(15);

    @include bp-large('max') {
      padding-top: rem_calc(10);
    }
  }
}
