.addtocalendar {
  max-width: rem_calc(350);
  width: 100%;
  white-space: nowrap;

  &:hover .atcb-link {
    color: $color-link-alt;
  }
}

.atcb-list {
  background-color: $color-link-alt;
  border-radius: 0 0 $global-radius $global-radius;
  box-shadow: rem_calc(0 5 20) $color-grey-lighter-medium;
}

.atcb-link {
  color: $color-white;
  border-radius: $global-radius;
  border: 1px solid $color-link-alt;

  &:focus,
  &:hover {
    color: $color-link-alt;
  }
}

.atcb-item-link {
  color: $color-white;

  &:hover {
    color: $color-white;
  }
}
