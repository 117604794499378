.c-main-nav__items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;

  .c-header--container & {
    font-size: rem_calc(15);

    @include bp-xlarge('max') {
      font-size: rem_calc(14);
    }
  }

  .c-header--static.c-header--image & {
    padding-top: 0;
    margin-top: rem_calc(10);
  }
}

.c-main-nav__item {
  margin-right: 0;
  margin-left: rem_calc(17);

  @include bp-xlarge-medium('max') {
    margin-left: rem_calc(5);
  }

  &:hover {
    > .c-main-nav__dropdown {
      transform: rotate3d(0, 1, 0, 0deg);
    }
  }

  &.menu-item-has-children#{&}--transparent {
    &:after {
      top: 60%;
    }

    &:before {
      display: none;
    }
  }
}

.c-main-nav__link {
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);
  letter-spacing: 1px;

  &::before {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    content: '';
    width: 9px;
    height: 9px;
    background-color: $color-theme;
    border-radius: 50%;
    opacity: 0;
    transition: $global-transition;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  .c-main-nav__item--active &,
  .current-menu-item &,
  .current-menu-ancestor &,
  .current-menu-parent &,
  .current-page-ancestor & {
    font-weight: 900;

    &::before {
      opacity: 1;
    }
  }

  .c-main-nav__item--transparent & {
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(10);
    border-bottom: none;

    .c-header--static:not(.c-header--shrink) & {
      padding-top: rem-calc(25);
      padding-bottom: rem-calc(10);
    }

    .c-header--static.c-header--image &,
    .c-header--static.c-header--none &,
    .c-header--none & {
      padding-top: rem-calc(25);
      padding-bottom: rem-calc(10);
    }
  }

  .c-main-nav__item.menu-item-has-children & {
    padding-right: rem-calc(22);
  }
}

.c-main-nav__full-dropdown {
  &-inner {
    margin-bottom: 0;
    padding-top: rem_calc(10);
    padding-bottom: rem_calc(15);
  }

  .c-main-nav__sublink {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(5);
    padding-right: rem_calc(30);
  }
}

.c-main-nav__full-dropdown--show-submenus {
  .c-main-nav__dropdown {
    .c-main-nav__sublink {
      font-size: rem-calc(15);
      line-height: rem-calc(18);
    }
  }
}

.c-main-nav__sublink {
  padding: rem-calc(5 10);
}

.c-main-nav__search {
  position: absolute;
  top: rem-calc(-36);
  right: 0;
}

.c-main-nav__search-icon {
  width: rem-calc(22);
  height: rem-calc(22);
}
