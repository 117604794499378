.c-listing__item {
  border-bottom: rem-calc(1) solid $color-grey-light-medium;
  margin-bottom: rem-calc(10);
  padding-bottom: rem-calc(10);
}

.c-listing__entry {
  padding-top: rem_calc(20);
  padding-bottom: rem_calc(10);

  &--above {
    margin: rem_calc(10 0 10);
  }

  &--within {
    margin: rem_calc(30 0);
    padding: 0;
    padding-bottom: rem_calc(10);
    border-bottom: none;
  }
}

.c-listing__link {
  color: $color-text;
  font-size: rem_calc(20);

  @include bp-medium('max') {
    font-size: rem_calc(18);
  }

  &:hover {
    color: $color-link;
  }
}

.c-listing__title {
  margin-bottom: rem_calc(20);

  &--top {
    padding: rem-calc(0 5);
  }

  @include bp-large('max') {
    margin-bottom: rem_calc(15);
  }

  a {
    font-weight: 700;
    text-decoration: none;
    transition: $global-transition;

    &:hover {
      text-decoration: none;
    }
  }
}
