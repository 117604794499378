.c-rwd-size {
  opacity: 0;
  display: none;

  @include bp-large('max') {
    &--large {
      display: block;
    }
  }

  @include bp-medium('max') {
    &--medium {
      display: block;
    }
  }
}
