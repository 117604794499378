body {
  font-size: rem_calc(16);
  line-height: rem_calc(25);

  @include bp-large('max') {
    font-size: rem_calc(15);
  }

  @include bp-medium('max') {
    font-size: rem_calc(14);
  }
}

p {
  font-size: inherit;
  line-height: inherit;

  .u-text--enlarge {
    font-size: rem_calc(18);
  }

  .u-text--ensmall {
    font-size: rem_calc(15);
  }

  @include bp-large('max') {
    .u-text--enlarge {
      font-size: rem_calc(17);
    }

    .u-text--ensmall {
      font-size: rem_calc(14);
    }
  }

  @include bp-medium('max') {
    .u-text--enlarge {
      font-size: rem_calc(16);
    }

    .u-text--ensmall {
      font-size: rem_calc(13);
    }
  }
}

.u-text--light {
  font-weight: 300;
}
