.c-members__categories-buttons {
  margin-top: rem_calc(10);

  li {
    padding-left: rem_calc(6);
    padding-right: rem_calc(6);
  }
}

.c-members__categories-dropdown {
  margin-top: rem_calc(20);
}

.c-member {
  display: flex;
  justify-content: flex-start;
  padding-bottom: rem-calc(30);

  &:nth-child(3n+2) {
    justify-content: center;
  }

  &:nth-child(3n+3) {
    justify-content: flex-end;
  }

  @include bp-large('max') {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include bp-small-medium('max') {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.c-member__inner {
  max-width: rem-calc(185);
  width: 100%;
  border-bottom: none;
  font-size: rem-calc(14);
  text-align: left;

  * {
    padding: 0 !important;
  }

  .c-member__image {
    padding-bottom: rem-calc(5) !important;
  }
}

.c-member__title {
  background-color: transparent;
  font-size: rem-calc(16);
  margin-bottom: 0;
  font-weight: bold;
}

.c-member__link {
  color: $color-black;

  &:hover {
    color: $color-black;
  }
}

.c-member__image {
  border: rem-calc(0) solid $color-grey-light-medium;

  img {
    display: block;
    margin: 0 auto;
  }
}

.c-member__email,
.c-member__phone {
  white-space: nowrap;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
