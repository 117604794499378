.select2-container .select2-selection--single {
  height: rem_calc(46);
  border: rem-calc(2) solid $color-grey-medium-light;
  transition: $global-transition;

  @include bp-xxlarge('max') {
    height: rem_calc(42);
  }

  @include bp-large('max') {
    height: rem_calc(40);
  }

  @include bp-medium('max') {
    height: rem_calc(38);
  }

  &.select2--small {
    height: rem_calc(42);
    border: rem-calc(2) solid $color-grey-medium-light;

    @include bp-medium('max') {
      height: rem_calc(38);
    }
  }

  &.select2--xsmall {
    height: rem_calc(36);
    border: rem-calc(2) solid $color-grey-medium-light;

    @include bp-medium('max') {
      height: rem_calc(34);
    }
  }

  &:hover {
    border: rem-calc(2) solid $color-theme-alt;
  }

  .x5-cform & {
    border-color: $color-theme;
    border-width: rem_calc(1);
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);
  line-height: rem_calc(44);
  color: $color-black;

  @include bp-xxlarge('max') {
    line-height: rem_calc(42);
  }

  @include bp-large('max') {
    line-height: rem_calc(40);
  }

  @include bp-medium('max') {
    line-height: rem_calc(28);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: rem_calc(38);

  @include bp-medium('max') {
    line-height: rem_calc(24);
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  line-height: rem_calc(32);

  @include bp-medium('max') {
    line-height: rem_calc(20);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: rem_calc(48);

  @include bp-xxlarge('max') {
    height: rem_calc(46);
  }

  @include bp-large('max') {
    height: rem_calc(44);
  }

  @include bp-medium('max') {
    height: rem_calc(42);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: rem_calc(43);

  @include bp-medium('max') {
    height: rem_calc(40);
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: rem_calc(38);

  @include bp-medium('max') {
    height: rem_calc(36);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: $color-black transparent transparent transparent;
  border-width: rem_calc(6) rem_calc(5) 0 rem_calc(5);
  transition: border-color 0.3s ease-in-out;

  @include bp-medium('max') {
    border-width: rem_calc(6) rem_calc(5) 0 rem_calc(5);
  }
}

.select2-container--default:hover .select2-selection--single .select2-selection__arrow b {
  border-color: $color-theme-alt transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $color-theme-alt transparent;
  border-width: 0 rem_calc(5) rem_calc(6) rem_calc(5);

  @include bp-medium('max') {
    border-width: 0 rem_calc(5) rem_calc(6) rem_calc(5);
  }
}

.select2-dropdown {
  background-color: $color-white;
  border: rem_calc(1) solid $color-grey-medium-light;

  &.select2--xsmall {
    border-width: rem_calc(1);
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  font-size: rem_calc(16);
  font-weight: 400;

  .x5-cform & {
    font-size: rem_calc(16);
  }

  @include bp-large('max') {
    font-size: rem_calc(16);
  }
}

.select2-container .select2-selection--single.select2--xsmall .select2-selection__rendered {
  font-size: rem_calc(16);
}

.select2-results__option {
  padding: rem_calc(7 10);
  font-size: rem_calc(15);

  @include bp-large('max') {
    font-size: rem_calc(15);
  }

  @include bp-medium('max') {
    font-size: rem_calc(15);
  }

  .select2-results__options.first-as-label &:first-child {
    padding: rem_calc(8 10);
    background-color: $color-theme-alt;
    color: $color-white;
  }

  .select2--small & {
    padding-top: rem_calc(5);
    padding-bottom: rem_calc(5);
  }

  .select2--xsmall & {
    padding: rem_calc(5 10);
  }
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $color-grey-lighter;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-theme-alt-light;
  color: $color-white;
}
