.c-sidebar {
  opacity: 0;

  &__custom-content {
    max-width: rem_calc($sidebar_max_width);

    .c-sidebar--4 & {
      max-width: rem_calc($sidebar_max_width_lg);
    }
  }

  &__inner {
    background-color: rgba(0, 103, 175, 0.2);

    @include bp-large('max') {
      max-width: rem-calc($sidebar_max_width_lg);
      margin: 0 auto;
    }
  }

  &--left {
    padding-left: 0;
  }

  &--right {
    padding-right: 0;
  }

  @include bp-large('max') {
    order: -1;
    padding-left: 0;
    padding-right: 0;
  }
}

.c-sidebar__widget {
  max-width: rem_calc($sidebar_max_width);
  margin-bottom: rem-calc(20);

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  .c-footer__sidebar & {
    max-width: none;
  }

  .c-sidebar--left &,
  .c-blocks__sidebar--left & {
    padding-right: rem_calc(40);
    padding-left: rem_calc(40);
  }

  @include bp-large('max') {
    &,
    .c-sidebar--left &,
    .c-blocks__sidebar--left & {
      padding-left: rem_calc(10);
      padding-right: rem_calc(10);
    }
  }

  &.widget_media_image {
    padding: 0;
    text-align: center;
  }
}

.c-sidebar__header {
  padding: rem_calc(30 0 25);
  margin: rem_calc(10 0 15);
  border-bottom: none;
  color: $color-text;
  font-size: rem_calc(20);
  font-weight: bold;
  text-transform: uppercase;

  @include bp-large('max') {
    padding: rem_calc(10 0);
  }
}

.widget_nav_menu,
.widget_pages,
.c-side-nav,
.widget_recent_entries {
  ul {
    ul {
      padding: 0;
    }

    li {
      .c-side-nav__sublink {
        padding: rem_calc(10 10);

        &--level2 {
          padding: rem_calc(10 20);
        }
      }

      a {
        padding: rem_calc(10 0);
        border: none;
        color: $color-text;
        transition: $global-transition;
        text-transform: uppercase;

        /* stylelint-disable selector-no-qualifying-type */

        &:hover,
        &.c-side-nav__link--current,
        &.c-side-nav__sublink--current {
          background-color: transparent;
        }

        &.c-side-nav__link--current,
        &.c-side-nav__sublink--current {
          font-weight: 900;
        }

        /* stylelint-enable selector-no-qualifying-type */
      }

      /* stylelint-disable */

      &.current-menu-item > a,
      &.current_page_item > a,
      .c-side-nav__link--current > a,
      .c-side-nav__link--current:hover > a {
        background-color: transparent;
        font-weight: bold;
      }

      .c-side-nav__link--month > a {
        padding: rem_calc(10 15 10 25);
      }

      /* stylelint-enable */
    }
  }
}

.widget_rss {
  ul {
    li {
      padding: rem_calc(10 15);
      border: rem_calc(1) solid $color-border;

      a {
        color: $color-grey-dark;

        &:hover {
          color: $color-link;
        }
      }
    }

    .rss-date {
      font-size: rem_calc(15);
      padding: rem_calc(5 0);
    }

    .rssSummary {
      font-size: rem_calc(14);
    }
  }
}

.widget_nav_menu {
  .sub-menu {
    a {
      padding: rem_calc(10 25);
    }

    .sub-menu {
      a {
        padding: rem_calc(10 35);
      }
    }
  }
}

.widget__html-content,
.widget_text .textwidget {
  padding: 0;
}

.widget__content-box-inner {
  max-width: rem_calc($sidebar_max_width);

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  @include bp-large('max') {
    max-width: rem_calc(420);
  }

  @include bp-medium('max') {
    max-width: rem_calc(380);
  }
}

.widget_x5_widget_upcoming_events {
  p {
    border: rem_calc(1) solid $color-border;
  }

  a {
    color: $color-black;
    transition: $global-transition;
  }

  &:hover a {
    background-color: $color-white;
  }

  li:hover a {
    background-color: $color-border;
  }
}

.widget__newsletter {
  background-color: $color-grey-lighter;

  &-link {
    color: $color-grey;

    &:hover .widget__newsletter-label {
      color: $color-grey;
    }
  }
}

.c-footer__sidebar {
  padding: rem_calc(20 26 30);
  background-color: $color-grey-lighter;

  @include bp-xlarge('max') {
    padding: rem_calc(10 0);
  }

  &-column {
    padding: rem_calc(0 15 10);

    .c-sidebar__widget {
      padding: 0;
    }

    &:nth-child(1) {
      padding-right: rem_calc(20);
      padding-left: rem_calc(10);
    }

    &:nth-child(3) {
      padding-right: rem_calc(10);
      padding-left: rem_calc(20);
    }

    @include bp-large('max') {
      &,
      &:nth-child(1),
      &:nth-child(3) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .c-sidebar__widget {
    max-width: none;
    margin: auto;

    @include bp-large('max') {
      max-width: rem_calc($sidebar_max_width);
      padding-left: rem_calc(10);
      padding-right: rem_calc(10);

      .c-sidebar--4 & {
        max-width: rem_calc($sidebar_max_width_lg);
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
