.c-pagination {
  line-height: rem-calc(30);
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  align-items: center;
  padding: rem-calc(0 5);
  margin: rem_calc(30 0 10 0);

  @include bp-xlarge('max') {
    line-height: rem-calc(30);
  }
}

.c-pagination__item {
  &--next,
  &--prev {
    display: none;
  }
}

.c-pagination__link {
  margin: 0;
  color: $color-theme-alt;
  font-size: rem_calc(16);
  font-weight: bold;
  border: none;

  @include bp-xlarge('max') {
    font-size: rem_calc(16);
  }

  .icon {
    top: rem_calc(2);
    width: rem-calc(20);
    height: rem-calc(16);
    fill: $color-theme-alt;
    transition: $global-transition;

    &-rotate {
      transform: rotate(180deg);
    }
  }

  &--next,
  &--prev {
    background-color: transparent !important;
  }

  &--next:hover {
    transform: translateX(5px);
  }

  &--prev:hover {
    transform: translateX(-5px);
  }

  &--current,
  &:hover {
    background-color: $color-theme-alt;
    border-color: $color-theme-alt;
    color: $color-white;

    .icon {
      fill: $color-theme-alt;
    }
  }
}
