.c-job__detail {
  margin-top: rem-calc(10);

  &-info {
    margin-bottom: rem-calc(30);
    padding-bottom: rem-calc(30);
    border-bottom: 1px solid $color-grey-light;
  }

  &-company,
  &-info {
    h5 {
      margin: rem-calc(5 0);
    }
  }

  &-company,
  &-application,
  &-contact {
    margin-top: rem-calc(30);
    padding-top: rem-calc(20);
    border-top: 1px solid $color-grey-light;
  }

  &-logo {
    margin: rem-calc(20 0);
    max-width: rem-calc(200);
  }
}
