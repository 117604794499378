.c-main-nav__trigger {
  width: rem_calc(30);

  .c-header--static.c-header--image & {
    margin-top: rem_calc(10);
  }

  .c-header--static.c-header--image .c-main-nav--open & {
    margin-top: rem_calc(0);
  }

  &::after,
  &::before {
    border-top-width: rem-calc(4);
  }
}
