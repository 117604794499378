select, textarea, input[type=text], input[type=password],
input[type=datetime], input[type=datetime-local],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email],
input[type=url], input[type=tel] {
  height: rem_calc(32);
  padding: rem-calc(0 10);
  border: rem-calc(1) solid $color-grey-lighter;
  border-bottom: rem-calc(2) solid $color-grey-medium-light;
  color: $color-grey;
  font-size: rem_calc(16);
  line-height: rem_calc(32);
  font-weight: 400;
  background-color: $color-white;
  transition: $global-transition;

  @include bp-large('max') {
    padding-bottom: 0;
  }

  @include bp-medium('max') {
    padding: rem-calc(2 10 0);
    font-size: rem_calc(16);
  }

  &::placeholder {
    color: $color-grey-lighter-medium;
    transition: $global-transition;
  }

  &:hover,
  &:focus {
    border: rem-calc(1) solid $color-grey-light-medium;
    border-bottom: rem-calc(2) solid $color-theme-alt;

    @include bp-medium('max') {
      border-width: rem-calc(1);
    }
  }
}

label {
  color: $color-grey;
  line-height: 1.6em;
  font-weight: 700;
}
