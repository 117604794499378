.c-header {
  transition: padding 0.3s ease-in-out, border 0.3s ease-in-out;

  &:not(.c-header--home) {
    box-shadow: -5px 3px 38px 0 rgba(0, 103, 175, 0.26);
  }

  &--floating {
    padding: rem-calc(10 60);

    @include bp-custom($bp-nav-full-padding--default, 'max') {
      padding: rem-calc(10 10);
    }

    @include bp-large('max') {
      &-nomobile {
        padding: rem-calc(0);
      }
    }
  }

  &--shrink {
    padding: 0;
  }

  &__inner {
    width: 100%;
    height: rem_calc(160);
    padding: rem-calc(50 10 10 10);

    @include bp-xlarge-medium('max') {
      height: rem_calc(120);
      padding: rem-calc(40 10 10 10);
    }

    @include bp-large('max') {
      height: rem_calc(80);
      padding: rem-calc(10);
    }
  }

  &--shrink &__inner {
    height: rem_calc(90);

    @include bp-large('max') {
      height: rem_calc(60);
    }
  }

  &--container &__inner {
    max-width: $header-max-width;
  }

  &--floating &__inner {
    height: rem_calc(90);

    @include bp-large('max') {
      height: rem_calc(60);
    }
  }

  &--floating:not(&--full) &__container {
    max-width: $header-max-width;

    .body__fixed-header--floating & {
      max-width: none;
    }
  }

  &--floating#{&}--shrink &__container {
    max-width: none;
  }

  &--static#{&}--image {
    .c-header__inner {
      align-items: flex-start;
      height: rem_calc(210);

      @include bp-xxlarge('max') {
        height: rem_calc(180);
      }

      @include bp-large('max') {
        height: rem_calc(120);
      }
    }
  }
}

.c-header__logo {
  width: 25%;

  .c-header--container & {
    width: 20%;
  }

  .c-header--static.c-header--image & {
    height: auto;
    max-height: 90px;

    img {
      max-height: 90px;
    }

    @include bp-large('max') {
      &,
      img {
        max-height: 50px;
      }
    }
  }

  a {
    height: 100%;
    width: 100%;
    max-width: rem_calc(300);
  }

  @include bp-xxlarge('max') {
    width: 20%;
  }

  @include bp-xlarge-medium('max') {
    width: 17%;
  }

  @include bp-large('max') {
    max-width: rem_calc(190);
    width: 100%;
  }
}

.c-header__nav {
  width: 75%;

  .c-header--container & {
    width: 80%;
  }

  @include bp-xxlarge('max') {
    width: 80%;
  }

  @include bp-xlarge-medium('max') {
    width: 83%;
  }

  @include bp-large('max') {
    width: auto;
  }
}

.c-header__mobile-actions {
  .c-header--static.c-header--image & {
    margin-top: rem_calc(15);
  }
}

.c-search {
  background-color: rgba($color-grey, 0.95);

  &__form {
    max-width: rem_calc(900);
    width: 95%;
    border: none;
    border-bottom: rem-calc(5) solid $color-theme-alt;

    @include bp-large('max') {
      border-width: rem-calc(5);
      max-width: rem_calc(500);
    }

    &:hover {
      border-bottom-color: $color-theme-alt !important;
    }
  }

  [type=text]#{&}__input {
    border: rem-calc(1) solid $color-grey-medium;
    border-bottom: none;
    border-right: none;
    background-color: transparent;
    height: rem-calc(50);
    color: $color-white;
  }

  &__btn {
    @extend .c-btn--primary;
    height: rem-calc(50);
    border-bottom: none !important;
    transition: $global-transition;
  }

  &__close {
    color: $color-theme-alt;

    &:hover {
      color: $color-white !important;
      text-decoration: none !important;
    }
  }
}
