.cform__entry {
  padding: rem_calc(20 0 30);
}

.c-form-group {
  label,
  legend {
    span {
      color: $color-error;
    }
  }

  .c-field__label {
    font-size: rem_calc(16);
    font-weight: 700;

    @include bp-medium('max') {
      font-size: rem_calc(16);
    }

    &--checkbox {
      min-height: rem_calc(25);
      margin: rem_calc(8 0);
      padding: rem_calc(0 0 0 5);

      @include bp-medium('max') {
        margin: rem_calc(5 0);
        padding: rem_calc(3 0 0 5);
      }

      [type=checkbox],
      [type=radio] {
        opacity: 0;
      }
    }

    &--vertical {
      display: inline-block;
      margin: rem_calc(2 0);
    }

    .c-icon {
      top: rem_calc(0);
      left: rem_calc(-2);
      position: absolute;
      display: inline-block;
      width: rem_calc(24);
      height: rem_calc(24);
      stroke-width: 0;
      stroke: $color-theme-alt;
      fill: $color-theme-alt;

      &--checkbox-unchecked {
        top: rem_calc(-2);
        left: rem_calc(-4);
        width: rem_calc(27);
        height: rem_calc(27);
      }

      &--checkbox-checked,
      &--radio-checked {
        opacity: 0;
      }

      &--radio-unchecked {
        top: rem_calc(2);
        left: rem_calc(-2);
        width: rem_calc(24);
        height: rem_calc(24);
      }

      &--radio-checked {
        top: rem_calc(3);
        left: rem_calc(-1);
        width: rem_calc(22);
        height: rem_calc(22);
      }

      @include bp-medium('max') {
        transform: scale(0.8);
      }
    }
  }

  legend {
    color: $color-text;
  }
}

.c-field__error {
  font-size: rem_calc(14);
  color: $color-error;

  @include bp-medium('max') {
    font-size: rem_calc(13);
  }
}

.c-field__message {
  font-size: rem_calc(15);

  &.show {
    width: 80%;
    padding: rem_calc(5 10);
    color: $color-white;
  }

  &.error {
    background-color: $color-error;
  }

  &.success {
    background-color: $color-success;
  }

  @include bp-xlarge('max') {
    font-size: rem_calc(15);
  }

  @include bp-medium('max') {
    font-size: rem_calc(14);
  }
}
