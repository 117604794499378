h1, h2, h3, h4, h5, h6 {
  color: $color-text;
  font-weight: 400;
  line-height: 1.5625em;

  .c-block__header &,
  .c-block__columns-column-title &,
  .c-footer__column-header & {
    color: $color-text;
  }
}

h1, h2, h3, h4, h5, h6 {
  strong {
    font-weight: 700;
  }
}

/* stylelint-disable selector-no-qualifying-type */

h1, .h1 {
  font-size: rem_calc(36);

  .c-block__slider-slide-caption & {
    font-size: rem-calc(60);

    @include bp-xxlarge('max') {
      font-size: rem-calc(48);
    }

    @include bp-xlarge('max') {
      font-size: rem-calc(36);
    }
  }

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(38);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(32);
  }
}

h2, .h2 {
  font-size: rem_calc(30);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(32);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(28);
  }
}

h3, .h3 {
  font-size: rem_calc(24);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(18);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(18);
  }
}

h4, .h4 {
  font-size: rem_calc(20);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(26);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(15);
  }
}

h5, .h5 {
  font-size: rem_calc(18);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(22);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(14);
  }
}

h6, .h6 {
  font-size: rem_calc(16);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(18);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(13);
  }
}

@include bp-xxlarge('max') {
  h1, .h1 {
    font-size: rem_calc(32);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(34);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(30);
    }
  }

  h2, .h2 {
    font-size: rem_calc(28);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(30);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(24);
    }
  }

  h3, .h3 {
    font-size: rem_calc(19);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(23);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(15);
    }
  }
}

@include bp-large('max') {
  h1, .h1 {
    font-size: rem_calc(28);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(30);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(24);
    }
  }

  h2, .h2 {
    font-size: rem_calc(24);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(26);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(18);
    }
  }
}
