.c-heading {
  border-bottom: none;
}

.c-heading--secondary {
  color: $color-black;
}

.c-heading--big {
  font-size: rem-calc(20);

  @include bp-medium {
    font-size: rem-calc(26);
  }
}

.c-page__title {
  padding-bottom: rem_calc(5);
  margin-bottom: rem_calc(5);
  font-weight: bold;
  font-size: rem-calc(50);
  color: $color-black;
  opacity: 0;

  &--pl {
    @include bp-large() {
      padding-left: rem-calc(30);
    }
  }

  .page-template-newsletter-list & {
    margin-bottom: rem_calc(15);
  }

  @include bp-xxlarge('max') {
    font-size: rem-calc(32);
  }

  @include bp-xlarge-medium('max') {
    font-size: rem-calc(28);
  }

  @include bp-large('max') {
    font-size: rem-calc(24);
    margin-bottom: 0;
  }

  // @include bp-medium {
  //   font-size: rem-calc(24);
  // }

  // @include bp-large {
  //   font-size: rem-calc(28);
  // }
}
