.c-front-page {
  &__sidebar {
    padding: rem-calc(50);
    background-color: $color-theme;

    img {
      max-width: calc(100% + 100px);
      margin-left: rem-calc(-50);
    }
  }

  .c-block__slider-slide {
    height: rem-calc(590);

    @include bp-xxlarge('max') {
      height: rem-calc(500);
    }

    @include bp-xlarge('max') {
      min-height: 1px !important;
      height: rem-calc(400);
    }

    @include bp-medium('max') {
      height: rem-calc(350);
    }
  }

  &__notification {
    position: relative;
    display: flex;
    margin-left: rem_calc(-25);
    width: calc(100% + 38px);
    align-items: center;
    padding: rem-calc(40);
    font-size: rem_calc(14);

    .c-icon {
      width: rem-calc(55);
      height: rem-calc(50);
      margin-right: rem-calc(40);
    }

    @include bp-large('max') {
      justify-content: center;
    }
  }

  &__posts {
    padding-top: rem-calc(30);

    > .o-layout__item {
      padding-left: rem-calc(40);

      @include bp-large('max') {
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
      }

      @include bp-medium('max') {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    a {
      transition: $global-transition;
    }

    &-header {
      display: flex;
      width: 100%;
      max-width: rem-calc(265);
      margin-bottom: rem-calc(20);
      justify-content: space-between;

      a {
        color: $color-theme-alt;

        &:hover {
          color: $color-theme-alt-dark;
        }
      }

      @include bp-large('max') {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__post {
    width: 100%;
    max-width: rem-calc(265);
    padding-bottom: rem-calc(10);

    @include bp-large('max') {
      margin: 0 auto;
    }

    a {
      display: inline-block;
      color: inherit;

      &:hover {
        color: $color-link;
      }
    }

    img {
      margin-bottom: rem-calc(15);
    }

    &-news {
      padding-top: rem-calc(10);
      border-top: 2px solid #eaeaea;
    }

    .c-btn {
      position: relative;
      margin-top: rem-calc(-15);
    }
  }

  &__links {
    margin-top: rem-calc(30);
  }

  &__link {
    @include bp-large('max') {
      text-align: center;
    }

    @include bp-medium('max') {
      flex: 0 0 100%;
      max-width: 100%;
    }

    h5 {
      font-size: rem-calc(15);
    }

    img,
    h5 {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      margin-right: rem-calc(20);
    }
  }
}
