.c-footer {
  background-color: $color-grey;
  border-top: rem_calc(2) solid $color-grey;

  &__column {
    @include bp-large('max') {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    &.u-margin-bottom,
    .u-margin-bottom-small {
      margin-bottom: 0 !important;
    }

    &-header {
      &--empty {
        padding: 0;
      }

      h3 {
        border-color: $color-theme-alt;
      }
    }
  }

  .c-footer__inner {
    max-width: 366px;
    padding: rem_calc(10 0 0);

    @include bp-large {
      max-width: 100%;
      padding: rem_calc(20 0 10);
    }
  }

  .c-menu__items {
    a {
      color: $color-link;
      transition: $global-transition;

      &:hover {
        color: $color-link-hover;
        text-decoration: underline;
      }
    }
  }
}

.c-footer__copyright {
  padding: rem_calc(10 0 20 0);
  background-color: $color-grey;
  font-size: rem_calc(11);
  border-top: none;
  color: $color-grey-medium-light;
  text-transform: uppercase;

  .c-footer__logo {
    max-width: rem_calc(160);
  }

  > .o-layout > .o-layout__item:not(:last-child) {
    @include bp-large() {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
}

.c-footer-nav {
  @include bp-large('max') {
    margin-top: rem-calc(10);
  }

  &__items {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__item {
    display: inline-block;
    margin-right: rem-calc(20);
  }

  &__link {
    color: $color-grey-medium-light;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    .c-main-nav__item--active & {
      color: $color-theme-alt;
    }
  }
}
