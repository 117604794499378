.page:not(.last-block-has-bg) main {
  padding-bottom: rem_calc(30);
}

.c-page-content__main {
  @include bp-xxlarge() {
    min-height: rem-calc(550);
  }

  .c-page-content--has-sidebar & {
    @include bp-large() {
      padding-left: rem-calc(40);

      &.o-layout {
        padding-left: rem-calc(30);
      }
    }
  }
}

.c-blocks__main--has-sidebar {
  @include bp-large() {
    padding-left: rem-calc(30);
  }
}
