.napeg-newletter-form {
  h6 {
    margin: rem-calc(5 25 5 0);
    color: $color-white;
    font-size: rem-calc(11);
    text-transform: uppercase;
  }

  h6,
  .form-group,
  button,
  input {
    display: inline-block;
  }

  h6,
  .form-group {
    vertical-align: top;
  }

  button,
  input {
    vertical-align: middle;
  }

  .form-group {
    position: relative;
  }

  button {
    position: absolute;
    top: rem-calc(7);
    right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      transform: translateX(5px);
    }
  }

  input {
    height: rem-calc(32);
    padding: rem-calc(5 35 5 0);
    border: none;
    border-bottom: 2px solid $color-grey-dark;
    font-size: rem-calc(14);
    background-color: transparent;
    color: $color-grey-medium-light;
    font-style: italic;

    &:hover,
    &:focus {
      border: none;
      border-bottom: 2px solid $color-theme-alt;
    }
  }

  .c-icon {
    width: rem-calc(22);
    height: rem-calc(14);
    fill: $color-theme-alt;
  }
}
