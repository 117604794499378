.c-entry__header {
  background-color: $color-grey-light-medium;
}

.c-entry__title {
  font-size: rem-calc(26);

  @include bp-medium {
    font-size: rem-calc(30);
  }

  @include bp-large {
    font-size: rem-calc(36);
  }
}

.c-entry__meta {
  font-size: rem-calc(16);
}

.c-entry__meta-item {
  border-left: none;
  padding-left: 0;
  margin: 0;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    margin-bottom: rem-calc(20);
  }
}

.c-entry__author {
  font-size: rem-calc(18);
}

.c-entry__content {
  font-size: rem-calc(18);

  @include bp-large() {
    &.o-layout__item--medium-8 {
      padding-left: rem-calc(41);
    }
  }
}

.c-entry__lead {
  font-size: rem-calc(24);
  line-height: rem-calc(42);
}
