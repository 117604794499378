.c-btn {
  font-size: rem_calc(16);
  font-weight: 400;
  padding: rem-calc(8 15);
  border: 1px solid $color-link-alt;

  .c-icon {
    width: rem-calc(26);
    height: rem-calc(18);
  }

  @include bp-xlarge('max') {
    padding: rem-calc(6 15);
  }

  @include bp-medium('max') {
    padding: rem-calc(5 10);
    font-size: rem_calc(15);
  }

  &--small {
    padding: rem-calc(5 10);
    font-size: rem_calc(15);

    @include bp-xlarge('max') {
      padding: rem-calc(4 10);
    }

    @include bp-medium('max') {
      padding: rem-calc(3 5);
      font-size: rem_calc(14);
    }

    .c-icon {
      width: rem-calc(22);
      height: rem-calc(14);
    }
  }

  &--medium {
    padding: rem-calc(10 20);

    @include bp-xlarge('max') {
      padding: rem-calc(8 20);
    }

    @include bp-medium('max') {
      padding: rem-calc(7 15);
    }

    .c-icon {
      width: rem-calc(30);
      height: rem-calc(22);
    }
  }

  &--large {
    padding: rem-calc(12 25);
    font-size: rem_calc(18);

    @include bp-xlarge('max') {
      padding: rem-calc(10 25);
      font-size: rem_calc(16);
    }

    @include bp-medium('max') {
      padding: rem-calc(9 20);
    }

    .c-icon {
      width: rem-calc(34);
      height: rem-calc(25);
    }
  }
}

// parameters - $bg-color, $bg-color-hover, $color, $color-hover, $border, $border-hover: $border

.c-btn--primary {
  @include btn-style(transparent, $color-theme-alt, $color-theme-alt, $color-white, rem-calc(2) solid $color-theme-alt);
}

.c-btn--primary-filled {
  @include btn-style($color-theme-alt, transparent, $color-white, $color-theme-alt, rem-calc(2) solid $color-theme-alt);
}

.c-btn--secondary {
  @include btn-style(transparent, $color-theme, $color-theme, $color-white, rem-calc(2) solid $color-theme);
}

.c-btn--secondary-filled {
  @include btn-style($color-theme, transparent, $color-white, $color-theme, rem-calc(2) solid $color-theme);
}

.c-btn--link {
  @include btn-style(transparent, $color-warning, $color-warning, $color-white, rem-calc(2) solid $color-warning);
}

.c-btn--link-filled {
  @include btn-style($color-warning, transparent, $color-white, $color-warning, rem-calc(2) solid $color-warning);
}

.c-btn--link-underline {
  @include btn-style(transparent, transparent, $color-grey-dark, $color-link, none);
  padding-left: 0;
  padding-right: 0;
  border-bottom: rem-calc(2) solid $color-theme-alt;

  &:hover,
  &:active,
  &:focus {
    border-bottom: rem-calc(2) solid $color-link;
  }
}

.c-btn--primary-light {
  @include btn-style($color-theme-alt-light, $color-theme-alt, $color-white, $color-white, rem-calc(2) solid $color-theme-alt-light, rem-calc(2) solid $color-theme-alt);
}

.c-btn--secondary-light {
  @include btn-style($color-theme-light, $color-theme, $color-white, $color-white, rem-calc(2) solid $color-theme-light, rem-calc(2) solid $color-theme);
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  margin: 0;
  padding: rem-calc(3 10);
  border: none;
}

.c-btn--arrow-primary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme-alt;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme-alt;
  }
}

.c-btn--arrow-secondary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme;
  }
}
