.gallery {
  .wp-caption-text {
    font-size: rem_calc(14);
    margin-top: 0;
  }

  @for $i from 1 through 9 {
    &-columns-#{$i} {
      .wp-caption-text {
        @if $i > 4 {
          font-size: rem_calc(14);
        }
      }
    }
  }
}
