.flatpickr {
  &-prev-month,
  &-next-month {
    &:hover svg {
      fill: $color-theme-alt !important;
    }
  }

  &-current-month {
    input.cur-year { /* stylelint-disable-line selector-no-qualifying-type */
      border: none;
    }
  }

  &-day {
    line-height: rem-calc(26);

    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
      background: $color-grey-lighter;
      border-color: $color-grey-lighter;
    }

    &.today {
      border-color: $color-theme-alt !important;

      &:hover,
      &:focus {
        border-color: $color-theme-alt !important;
        background: $color-theme-alt !important;
        color: #fff !important;
      }
    }

    &.selected,
    &.startRange,
    &.endRange,
    &.selected.inRange,
    &.startRange.inRange,
    &.endRange.inRange,
    &.selected:focus,
    &.startRange:focus,
    &.endRange:focus,
    &.selected:hover,
    &.startRange:hover,
    &.endRange:hover,
    &.selected.prevMonthDay,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.selected.nextMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay {
      background: $color-theme-alt;
      border-color: $color-theme-alt;
    }

    &.selected.startRange + .endRange,
    &.startRange.startRange + .endRange,
    &.endRange.startRange + .endRange {
      box-shadow: -10px 0 0 $color-theme-alt;
    }

    &.startRange,
    &.prevMonthDay.startRange,
    &.nextMonthDay.startRange,
    &.endRange,
    &.prevMonthDay.endRange,
    &.nextMonthDay.endRange {
      border-color: $color-theme-alt;
    }
  }

  &-time {
    input {
      border: solid rem-calc(1) $color-grey-lighter !important;
    }
  }
}
