.c-hero--imageless {
  background-color: $color-grey-light-medium;
}

.c-hero__meta {
  font-size: rem-calc(14);

  @include bp-medium {
    font-size: rem-calc(16);
  }

  @include bp-large {
    font-size: rem-calc(18);
  }

  p {
    display: none;
  }
}

.c-hero__img {
  opacity: 0.5;
  margin-left: auto;
  margin-right: auto;
}
