table:not(.c-events-calendar__table) {
  td {
    font-size: rem_calc(14);
  }
}

.c-x5__table {
  background-color: $color-white;

  &--shadow {
    box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.3);
  }

  &--border-grey,
  &--header-bordered#{&}--border-grey th,
  &-rows--bordered#{&}--border-grey td,
  &-cols--bordered#{&}--border-grey th,
  &-cols--bordered#{&}--border-grey td,
  &-rows--striped-bordered#{&}--border-grey td,
  &-rows--striped-bordered-reverse#{&}--border-grey td,
  &-cols--striped-bordered#{&}--border-grey th,
  &-cols--striped-bordered#{&}--border-grey td,
  &-cols--striped-bordered-reverse#{&}--border-grey th,
  &-cols--striped-bordered-reverse#{&}--border-grey td {
    border-color: $color-grey;
  }

  &--border-black,
  &--header-bordered#{&}--border-black th,
  &-rows--bordered#{&}--border-black td,
  &-cols--bordered#{&}--border-black th,
  &-cols--bordered#{&}--border-black td,
  &-rows--striped-bordered#{&}--border-black td,
  &-rows--striped-bordered-reverse#{&}--border-black td,
  &-cols--striped-bordered#{&}--border-black th,
  &-cols--striped-bordered#{&}--border-black td,
  &-cols--striped-bordered-reverse#{&}--border-black th,
  &-cols--striped-bordered-reverse#{&}--border-black td {
    border-color: $color-black;
  }

  thead {
    background-color: $color-black;
    color: $color-white;
  }

  &--header-grey thead {
    background-color: $color-grey-light;
  }

  &--header-white thead {
    background-color: $color-white;
    color: $color-black;
  }

  td {
    font-size: rem_calc(14);
    line-height: 1.2em;
  }

  &-rows--striped tbody tr:nth-child(odd) td,
  &-rows--striped-bordered tbody tr:nth-child(odd) td,
  &-rows--striped-reverse tbody tr:nth-child(even) td,
  &-rows--striped-bordered-reverse tbody tr:nth-child(even) td {
    background-color: $color-grey-lighter;
  }

  &-cols--striped th:nth-child(odd),
  &-cols--striped td:nth-child(odd),
  &-cols--striped-reverse th:nth-child(even),
  &-cols--striped-reverse td:nth-child(even),
  &-cols--striped-bordered th:nth-child(odd),
  &-cols--striped-bordered td:nth-child(odd),
  &-cols--striped-bordered-reverse th:nth-child(even),
  &-cols--striped-bordered-reverse td:nth-child(even) {
    background-color: $color-grey-lighter;
  }

  &--border-grey td,
  &--border-grey th {
    border-color: $color-grey;
  }

  @include bp-medium {
    &--striped tbody tr:nth-child(even),
    &--striped-reverse tbody tr:nth-child(odd),
    &--striped-cols td:nth-child(even),
    &--striped-cols#{&}--header-white th:nth-child(even),
    &--striped-cols-reverse td:nth-child(odd),
    &--striped-cols-reverse#{&}--header-white th:nth-child(odd) {
      background-color: $color-grey-lighter;
    }
  }

  &--row-small td,
  &--header-small th {
    padding: rem_calc(10 10) !important;
  }

  &--row-medium td,
  &--header-medium th {
    padding: rem_calc(15 10) !important;
  }

  &--row-large td,
  &--header-large th {
    padding: rem_calc(25 10) !important;
  }

  &--header-grey thead th {
    background-color: $color-grey-light !important;
  }

  &--header-black thead th {
    background-color: $color-black !important;
  }

  &--header-white thead th {
    background-color: $color-white !important;
  }
}

table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table),
.c-x5__table--editor {
  border: solid 1px $color-grey;
  border-right: none;
  border-bottom: none;
  border-left: none;

  td, th {
    border-bottom: 1px solid $color-grey;
    border-right: none;
    border-left: none;
  }
}
